<template lang="pug">
.invoice-page
  .invoice-page-container
    div(v-if='invoice')
      .columns#invoice-body(:data-invoice-id='invoice.id')
        .column
          div
            span.item-title Fecha de emisión:
            span.item-value {{ invoice.date | date }}
          div
            span.item-title Fecha de vencimiento:
            span.item-value {{ invoice.expireDate | date }}
          div(v-if='contract.code')
            span.item-title Contrato:
            span.item-value {{ contract.code }}
          div
            span.item-title Periodo:
            span.item-value {{ invoice.startDate | date }} - {{ invoice.endDate | date }}
        .column
          //- img(src='https://efactura.edasystem.com/assets/edasystem-logo-70px-color.png')
      .divider
      .columns
        .column
          div
            b Vendedor
            div EDWIN JOHAN GOMEZ RESTREPO
            div Carrera 64a # 25 - 116, Itagui - Colombia
            div CC: 1026146676
            div Teléfono: 3043365729
        .column
          div
            b Cliente
            div {{ buyer.name }}
            div {{ buyer.address }}
            div {{ buyer.identificationType || 'NIT' }}: {{ buyer.identification || 'NA' }}
            div Teléfono: {{ buyer.phone || 'NA' }}
      table
        thead(v-if='details.length')
          tr
            th Nombre registrado
            th IMEI
            th Registrado
            th.numeric Cantidad
            th.numeric Precio/{{chargeFrecuencyLabel}}
            th.numeric Subtotal
        tbody(v-if='details.length')
          tr(v-for='detail in details')
            td {{ detail.device.name }}
            td {{ detail.deviceId }}
            td {{ detail.device.registerDate | date }}
            td.numeric {{ isDaily ? detail.days : detail.months  }} {{ chargeFrequencyTypeDescription }}
            td.numeric {{ detail.value / (isDaily ? detail.days : detail.months) | currency }}
            td.numeric {{ detail.value | currency }}
          tr
            td(colspan='4')
            td.numeric
              b Acumulado:
            td.numeric
              b {{ totalDetails | currency }}
        thead(v-if='otherDetails.length')
          tr
            th(colspan='3') Otros conceptos
            th.numeric Cantidad
            th.numeric Precio
            th.numeric Subtotal
        tbody(v-if='otherDetails.length')
          tr(v-for='detail in otherDetails')
            td(colspan='3') {{ detail.concept }}
            td.numeric {{ detail.quantity }}
            td.numeric {{ detail.unitCost | currency }}
            td.numeric {{ detail.unitCost * detail.quantity | currency }}
          tr
            td(colspan='4')
            td.numeric
              b Acumulado:
            td.numeric
              b {{ totalOtherDetails | currency }}
        tbody
          tr
            td(colspan='4')
            td.numeric
              b Saldo a favor:
            td.numeric
              b {{ contract.positiveBalance || 0 | currency }}
          tr
            td(colspan='4')
            td.numeric
              b Total:
            td.numeric
              b {{ totalDetails + totalOtherDetails | currency }}

      .divider
      p Número de cuenta: 002-04648-379 Ahorros Bancolombia. Por favor envíe su soporte de pago a egomez@edasystem.com
      .mark-as-printed
        input#filenme(type='text' v-model='fileName' placeholder='Nombre del archivo')
        button#mark-as-printed(@click='markAsPrinted') Marcar como impreso
    p(v-else) No hay facturas pendientes, recargue la página para reintentar
</template>

<script>
import invoicesBotService from '@/services/invoices-bot.service'
import toastService from '@/services/toast.service'
import { chargeFrequencyTypeEnum, chargeFrequencyTypeDescriptions } from '@/constants/enums'
export default {
  data () {
    return {
      invoice: null,
      fileName: ''
    }
  },
  created () {
    this.getNextInvoice()
  },
  computed: {
    details () {
      return (this.invoice && this.invoice.invoiceDetails) || []
    },
    otherDetails () {
      return (this.invoice && this.invoice.invoiceOtherDetails) || []
    },
    contract () {
      return (this.invoice && this.invoice.contract) || {}
    },
    buyer () {
      return (this.invoice && this.invoice.buyer) || {}
    },
    totalDetails () {
      return this.details.reduce((accum, detail) => accum + detail.value, 0)
    },
    totalOtherDetails () {
      return this.otherDetails.reduce((accum, detail) => accum + detail.unitCost * detail.quantity, 0)
    },
    chargeFrecuencyLabel () {
      switch (this.invoice.chargeFrequency) {
        case chargeFrequencyTypeEnum.Daily: return 'día'
        case chargeFrequencyTypeEnum.Montly: return 'mes'
        default: return 'día?'
      }
    },
    chargeFrequencyTypeDescription () {
      return chargeFrequencyTypeDescriptions[this.invoice.chargeFrequency]
    },
    isDaily () {
      return this.invoice.chargeFrequency === chargeFrequencyTypeEnum.Daily
    }
  },
  methods: {
    async getNextInvoice () {
      this.invoice = null
      const result = await invoicesBotService.pendingForPrint()
      if (result && result.id) {
        this.invoice = result
      }
    },
    async markAsPrinted () {
      const invoice = {
        id: this.invoice.id,
        file: this.fileName
      }
      const result = await invoicesBotService.savePendingForPrintFile(invoice)
      if (result) {
        toastService.show('Factura actualizada', 500)
        this.fileName = ''
        this.getNextInvoice()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice-page {
  background: white;
  font-size: 12px;
}

.invoice-page-container {
  max-width: 1000px;
  margin: 0 auto;
}

.item-title {
  font-weight: bold;
  display: inline-block;
  margin-right: 10px;
}

.divider {
  background: #afafaf;
  height: 1px;
  margin: 15px 0;
}

table {
  width: 100%;
}

.numeric {
  text-align: right;
}

tbody tr:last-child td {
  padding-bottom: 20px;
}

/* override styles when printing */
@media print {
  .mark-as-printed {
    display: none;
  }
}
</style>
